import React from 'react';
import './App.css';
//import Navbaar from './components/navbar'
import Navbaar from './components/nav2'
import InfoPage from './components/infoPage'
import Footer from './components/footer'
import { BrowserRouter as Router, Route } from 'react-router-dom'
//import Instruments from './components/instruments2'
import Blog from './components/blog'

//import Muskari from './components/muskari'

//import Chat from './chat/chatbot'
//import Toimipisteet from './components/toimipisteet'
import Toimipisteet2 from './components/Toimipisteet/toimipisteet';
import Esiintyjä from './components/esiintyjavalitys'
//import Hinnasto2 from './components/hinnasto2'
import Laskutus from './components/laskutus'
//import Opinnot from './components/opinnot';
//import Testi from './components/testiInstumets';
import Faq from './components/faq2';
import * as Scroll from 'react-scroll';
import Opettajat from './components/opettajat';
import reactGa from 'react-ga';
import { Helmet } from 'react-helmet';
import Saavutettavuus from './components/saavutettavuus';
import Konsertit from './components/konsertit';
//import InfoBAnner from './components/infobanner';
import HinnastoAvoin from './components/hinnastoAvoin';
import HinnastoMuskari from './components/hinnastoMuskari';
import HinnastoPerusopetus from './components/hinnastoPerus';
import Hinnasto from './components/hinnastoMain';
import Info from './components/info';

//import Opiskelu from './components/tietoaOpiskelusta';

import Oppilaalle from './components/oppilaalle';
import Viikonloppukursseja from './components/viikonloppukurssit'
import Muskari2 from './components/Muskari/Muskari';
import Opiskelu2 from './components/Opiskelusta/tietoaOpiskelusta';

let scroll1 = Scroll.animateScroll;

function App() {
  
  
  reactGa.initialize('UA-173490504-1');
  reactGa.pageview(window.location.pathname);


  
/*
  const open = () => {
    document.getElementById('myForm').style.display = "block"
    document.querySelector('.chat').classList.add('w3-animate-left')
    document.getElementById("open-button").style.display = "none"
   

  }
 */ 
  const options = {
    "smooth": true,
    "hashSpy":true,
    "offset":50,
    "duration":500,
    "delay":10
    
  }

  const RollTop = () => {
   // window.scrollTo(500, 0)
   scroll1.scrollToTop(options);
  }
  const ArrowUp = () => {
  
    return(
    
      <div className="arrow-up ">
         <i onClick={RollTop} className="fa fa-chevron-up fa-1x"></i>
      </div>
    )
    
    
  }
  

  return (
    <div className="extra">
    
    <Helmet>
    <title>KUM -Keskisen Uudenmaan Musiikkiopisto</title>
    
      <meta name="description" content="Tervetuloa opiskelemaan Keskisen Uudenmaan Musiikkiopistoon. Olemme Järvenpään kaupungin ja Tuusulan kunnan yhteinen musiikkiopisto, ja meillä voi opiskella sekä klassista musiikkia että pop/jazzmusiikkia innostavien ja ammattitaitoisten opettajien johdolla.  
      Musiikkiopistomme tarjoaa mukavia hetkiä soittamisen, laulamisen ja muskarin parissa, innostavia esiintymismahdollisuuksia ja myönteisiä tunne-elämyksiä." />
    
      <link rel="canonical" href="http://kum.fi" />
    </Helmet>
    <Router>
    
    <div className="App">
    
      
      <Navbaar />
     
      
    
      <ArrowUp />
      {/* <Chat /> */}
      <Route path="/" exact component={InfoPage} />
      {/*<Route path="/soittimet" component={Instruments} /> */}
      <Route path="/ajankohtaiset/:id" component={Blog} />
      <Route path="/muskari" component={Muskari2} />
      <Route path="/toimipisteet" component={Toimipisteet2} />
      {/*<Route path="/hinnasto" component={Hinnasto2} /> */}
      <Route path="/esiintyjävälitys" component={Esiintyjä} />
      <Route path="/laskutustiedot" component={Laskutus} />
      {/* <Route path="/opinnot" component={Opinnot} /> */}
      {/*<Route path="/soitin/:id" component={Testi}/> */}
      <Route path="/opettajat" component={Opettajat} />
      <Route path="/faq" component={Faq} />
      <Route path="/saavutettavuusseloste" component={Saavutettavuus} />
      <Route path="/konsertit" component={Konsertit} />

      <Route path="/hinnastoavoin" component={HinnastoAvoin} />
      <Route path="/hinnastomuskari" component={HinnastoMuskari} />
      <Route path="/hinnastoperusopetus" component={HinnastoPerusopetus} />
      <Route path="/maksuohjeet" component={Hinnasto} />

      <Route path="/info" component={Info} />

     { /* <button onClick={open} id="open-button" className="open-button"><h4>Chat</h4></button> */}

      <Route path="/opiskelu" component={Opiskelu2}  />
      <Route path="/oppilaalle" component={Oppilaalle} />
      <Route path="/viikonloppukurssit" component={Viikonloppukursseja} />
      
      <Footer />
    </div>
    </Router>
    </div>
  );
}

export default App;
