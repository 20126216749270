import React, { useState, useEffect, useRef } from 'react';
import './nav2.css';
import logo from '../images/logoNav.png';

function NavBar() {
  const [scrollY, setScrollY] = useState(0);
  const [oldScrollY, setOldScrollY] = useState(0);
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [increment, setIncrement] = useState(0);
  const navRef = useRef(null);
  const socialRef = useRef(null);
  const opinnotContentRef = useRef(null);
  const opinnotButtonRef = useRef(null);
  const infoContentRef = useRef(null);
  const infoButtonRef = useRef(null);
  const yhteysContentRef = useRef(null);
  const yhteysButtonRef = useRef(null);
  const ajanContentRef = useRef(null);
  const ajanButtonRef = useRef(null);

  useEffect(() => {
    const listenScroll = () => {
      const newValue = window.pageYOffset;

      if (oldScrollY - newValue < -50 && increment % 2 === 0) {
        if (navRef.current) {
          navRef.current.style.display = 'none';
        }
      } else if (oldScrollY - newValue > 0 && increment % 2 === 0) {
        if (navRef.current) {
          navRef.current.style.backgroundColor = 'white';
          navRef.current.style.display = 'flex';
          navRef.current.style.animation = 'mymove 0.3s';
        }
      }

      setOldScrollY(newValue);
      setScrollY(newValue);
    };

    window.addEventListener('scroll', listenScroll);

    return () => {
      window.removeEventListener('scroll', listenScroll);
    };
  }, [oldScrollY, increment]);

  const navSlide = () => {
    setIncrement((prevIncrement) => prevIncrement + 1);
    setBurgerOpen((prevBurgerOpen) => !prevBurgerOpen);

    const navLinks = document.querySelector('.nav-links');
    if (navLinks) {
      navLinks.classList.toggle('nav-active');
    }

    const burger = document.querySelector('.burger');
    if (burger) {
      burger.classList.toggle('toggle');
    }

    if (increment % 2 === 0) {
      if (socialRef.current) {
        socialRef.current.style.display = 'block';
      }
    } else {
      setTimeout(() => {
        if (socialRef.current) {
          socialRef.current.style.display = 'none';
        }
      }, 1000);
    }
  };

  const Paluu = () => {
    window.location.href = '/';
  };

  const muskari = () => {
    window.location.href = '/muskari';
  };

  const fag = () => {
    window.location.href = '/faq';
  };

  const ilmoittaudu = () => {
    window.open('https://kum.eepos.fi/#/splash', '_blank');
  };

  const closeAllDropdowns = (except = null) => {
    const dropdowns = document.querySelectorAll('.dropdown-content');
    const dropButtons = document.querySelectorAll('.dropbtn');

    dropdowns.forEach((dropdown, index) => {
      const button = dropButtons[index];
      if (dropdown !== except && dropdown.classList.contains('show')) {
        dropdown.classList.remove('show');
        if (button) {
          button.classList.remove('under');
        }
      }
    });
  };

  const opinnot = () => {
    closeAllDropdowns(opinnotContentRef.current);
    if (opinnotContentRef.current && opinnotButtonRef.current) {
      opinnotContentRef.current.classList.toggle('show');
      opinnotButtonRef.current.classList.toggle('under');
    }
  };

  const Yhteystiedot = () => {
    closeAllDropdowns(yhteysContentRef.current);
    if (yhteysContentRef.current && yhteysButtonRef.current) {
      yhteysContentRef.current.classList.toggle('show');
      yhteysButtonRef.current.classList.toggle('under');
    }
  };

  const info = () => {
    closeAllDropdowns(infoContentRef.current);
    if (infoContentRef.current && infoButtonRef.current) {
      infoContentRef.current.classList.toggle('show');
      infoButtonRef.current.classList.toggle('under');
    }
  };

  const ajan = () => {
    closeAllDropdowns(ajanContentRef.current);
    if (ajanContentRef.current && ajanButtonRef.current) {
      ajanContentRef.current.classList.toggle('show');
      ajanButtonRef.current.classList.toggle('under');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.matches('.dropbtn')) {
        closeAllDropdowns();
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="main-nav" id="oma-nav" ref={navRef}>
      <div className="nav-upper">
        <div className="upper-logo">
          <h3 onClick={Paluu}>KUM </h3>
          <img src={logo} alt="logo" />
        </div>

        <div className="upper-social" id="upper-s" ref={socialRef}>
          <a href="https://www.facebook.com/KeskisenUudenmaanMusiikkiopisto" target="blank" rel="noopener noreferrer">
            <i className="fa fa-facebook fa-3x"></i>
          </a>
          <a href="https://www.instagram.com/musiikkiopisto_kum/" target="blank" rel="noopener noreferrer">
            <i className="fa fa-instagram fa-3x"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCnPoZNsDmJ0-bibMtaIvOmw?view_as=subscriber" target="blank" rel="noopener noreferrer">
            <i className="fa fa-youtube fa-3x"></i>
          </a>
        </div>

        <div onClick={navSlide} className={`burger ${burgerOpen ? 'toggle' : ''}`}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </div>
      <div className="own-nav">
        <ul id="what" className={`nav-links ${burgerOpen ? 'nav-active' : ''}`}>
          <li className="dropdown">
            <button onClick={opinnot} id="opinnot-button" className="dropbtn" ref={opinnotButtonRef}>
              Musiikin opiskelu{' '}
              <span>
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </span>
            </button>
            <div id="opinnot-content" className="dropdown-content" ref={opinnotContentRef}>
              <a href="/opiskelu">Tietoa opiskelusta</a>
              <a href="/oppilaalle">Oppilaalle</a>
              <a href="/viikonloppukurssit">Viikonloppukurssit</a>
            </div>
          </li>

          <li className="dropdown">
            <button onClick={muskari} className="dropbtn">
              Muskari{' '}
            </button>
          </li>
          <li className="dropdown">
            <button onClick={ajan} id="ajan-button" className="dropbtn" ref={ajanButtonRef}>
              Ajankohtaista{' '}
              <span>
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </span>
            </button>
            <div id="ajan-content" className="dropdown-content" ref={ajanContentRef}>
              <a href="/ajankohtaiset/firstpage">Tiedotteet</a>
              <a href="/konsertit">Tapahtumat</a>
            </div>
          </li>

          <li className="dropdown">
            <button onClick={info} id="info-button" className="dropbtn" ref={infoButtonRef}>
              Info{' '}
              <span>
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </span>
            </button>
            <div id="info-content" className="dropdown-content" ref={infoContentRef}>
              <a href="/info">Infoa Musiikkiopistosta</a>
              <a href="/hinnastoperusopetus">Hinnasto</a>
              <a href="/esiintyjävälitys">Esiintyjävälitys</a>
              <a href="https://kum.eepos.fi/#/splash">Eepos</a>
            </div>
          </li>
          <li className="dropdown">
            <button onClick={fag} className="dropbtn">
              FAQ
            </button>
          </li>
          <li className="dropdown">
            <button onClick={Yhteystiedot} id="yhteys-button" className="dropbtn" ref={yhteysButtonRef}>
              Yhteystiedot{' '}
              <span>
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </span>
            </button>
            <div id="yhteys-content" className="dropdown-content" ref={yhteysContentRef}>
              <a href="/opettajat">Opettajat</a>
              <a href="/toimipisteet">Yhteystiedot</a>
              <a href="/laskutustiedot">Laskutustiedot</a>
            </div>
          </li>

          <li className="dropdown">
            <button onClick={ilmoittaudu} className="dropbtn">
              Ilmoittaudu
            </button>
          </li>

          <div className="social2" id="social-id" style={{ marginTop: '10px' }}>
            <a href="https://www.facebook.com/KeskisenUudenmaanMusiikkiopisto" target="blank" rel="noopener noreferrer">
              <i className="fa fa-facebook fa-3x"></i>
            </a>
            <a href="https://www.instagram.com/musiikkiopisto_kum/" target="blank" rel="noopener noreferrer">
              <i className="fa fa-instagram fa-3x"></i>
            </a>
            <a href="https://www.youtube.com/channel/UCnPoZNsDmJ0-bibMtaIvOmw?view_as=subscriber" target="blank" rel="noopener noreferrer">
              <i className="fa fa-youtube fa-3x"></i>
            </a>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default NavBar;