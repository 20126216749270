import React from 'react';
import './MuskariContentPage.css';
import VauvaMuskari from './images/vauvamuskari.jpeg'
//import image1 from './images/1.jpeg'
import image2 from './images/10.jpeg'
import Laulu from './images/laulu.png'
import image4 from './images/ryhma.png';

const MuskariContentPage = () => {
      return (
        <div className="content-page">
        <section className="article-section">
          <article className="article" imageFirst={true}>
            <div className="article-content">
              <img className="image-1"  src={VauvaMuskari} alt="Kum muskari vauvamuskari järvenpää ja tuusula" />
              <div className="text-content">
               <br></br>
               <br></br>
               <br></br>
                <h2>0–2-vuotiaiden muskarit</h2>
                <p>Vauvamuskarissa, 1-vuotiaiden taaperomuskarissa ja 2-vuotiaiden muksumuskarissa vanhempi ja lapsi nauttivat yhdessä musiikin riemusta. Ryhmissä körötellään, leikitään, lauletaan, soitetaan ja kuunnellaan musiikkia sekä liikutaan musiikin tahdissa.</p>
                <p>Tunnit suunnitellaan lapsen ikätason mukaan, ja muskari tukee lapsen kokonaisvaltaista kehitystä ja tarjoaa iloa ja elämyksiä. Ryhmä kokoontuu kerran viikossa 45 minuutin tunnille, jolle tullaan oman aikuisen kanssa.</p>
              </div>
            </div>
          </article>
  
          <article className="article" imageFirst={false}>
            <div className="article-content">
              <div className="text-content">
                <h2>3–4-vuotiaat taitoniekat</h2>
                <p>Taitoniekoissa lapsi tutustuu musiikkiin elämyksellisesti ja leikinomaisesti mm. laulaen, loruillen, soittaen, liikkuen, kuunnellen ja leikkien. Tunnit suunnitellaan lapsen ikätason mukaan, jolloin muskari tukee lapsen kokonaisvaltaista kehitystä ja tarjoaa iloa ja elämyksiä</p>
                <p>Oppitunnin pituus on 45 minuuttia ja tunnille osallistutaan itsenäisesti ilman omaa aikuista. Lapsi voi tulla mukaan ryhmään, kun aika on sopiva harrastukseen ilman omaa vanhempaa. 
                </p>
              </div>
              <img className="image-2"src={image2} alt="Kum muskari 3-4 vuotiaat" />
            </div>
          </article>
  
          <article className="article" imageFirst={false}>
            <div className="text-content">
              <h2>5-vuotiaat soittoniekat</h2>
                <p>Soitinryhmässä tutustutaan soittamiseen leikinomaisesti laulua säestäen ja melodioita etsien. Soittimina ovat mm. 5-kielinen kantele ja ukulele (oma soitin ei ole edellytys osallistumiselle) sekä muut muskarin rytmi- ja melodiasoittimet.</p>
                <p>Lapsi oppii uusia taitoja ja saa onnistumisen elämyksiä musiikista nauttien.Oppitunnin pituus on 45 minuuttia ja tunnille osallistutaan itsenäisesti ilman omaa aikuista.</p>
            </div>
          </article>
  
          <article className="article" imageFirst={true}>
            <div className="article-content">
              <img className="image-3" src={image4} alt="Kum muskari 6 vuotiaat" />
              <div className="text-content">
                <br></br>
                <br></br>
                <h2>6-vuotiaat soittoniekat</h2>
                <p>6-vuotiaiden soitinryhmässä jatketaan 5-kielisen kantelen ja ukulelen soittoon sekä muskarisoittimiin tutustumista leikinomaisesti ja elämyksellisesti (oma soitin ei ole edellytys osallistumiselle). Lapsi oppii uutta ja vahvistaa aiemmin opittuja taitojaan ja saa onnistumisen elämyksiä musiikista nauttien. Oppitunnin pituus on 60 minuuttia.</p>
              </div>
            </div>
          </article>
          <article className="article" imageFirst={true}>
            <div className="article-content">
              <div className="text-content">
                <h2>5–6-vuotiaat soittoniekat, poikkihuilupainotus</h2>
                <p>Tässä 5–6-vuotiaiden soitinryhmässä tutustutaan poikkihuilun soiton alkeisiin Suzuki-menetelmällä (oma soitin ei ole edellytys osallistumiselle). ja ryhmässä soitetaan myös muita muskarin rytmi- ja melodiasoittimia. Lapsi oppii uusia taitoja ja saa onnistumisen elämyksiä musiikista nauttien.Oppitunnin pituus vaihtelee ryhmän koon mukaan.</p>
              </div>
            </div>
          </article>
          <article className="article" imageFirst={true}>
            <div className="article-content">
              <div className="text-content">
                <h2>7–8-vuotiaiden Crescendo</h2>
                <p>Crescendo-musiikkivalmennusryhmissä leikitään, lauletaan ja soitetaan erilaisilla soittimilla (esim. lasten omat instrumentit ja muskarin rytmi- ja melodiasoittimet). Opetus tukee soittoharrastusta ja sen aloitusta ja sisältyy taiteen perusopetuksen oppilaille lukukausimaksuun</p>
                <p>Lapsi oppii uutta ja vahvistaa aiemmin opittuja taitojaan ja saa onnistumisen elämyksiä musiikista nauttien Oppitunnin pituus on 60 min.</p>
              </div>
            </div>
          </article>
          <article className="article" imageFirst={true}>
          <div className="article-content">
            
            <div className="text-content">
              <h2>Lauluvalmennus</h2>
                <p>Lauluvalmennus on lasten laulunopetusta Suzuki-menetelmällä n. 3–9-vuotiaille lapsille. Tunneilla rohkaistaan oman äänen löytämiseen ja käyttämiseen monipuolisesti erilaisten laulujen ja leikin kautta. Opetus tapahtuu pareittain ja jokainen oppilas saa opetusta yhteensä 30 minuuttia viikossa, josta 15 min. on yksilöopetusta ja 15 min. pariopetusta.</p>
            </div>
            <img className="image-4" src={Laulu} alt="Kum muskari kuva Lauluvalmennus" />
          </div>
        </article>
        </section>
      </div>
      );
};
    



export default MuskariContentPage;