import React from 'react';
import './Muskari.css';
import MukariContent from './MuskariContnet';
//import MuskariViulu from './images/muskari-viulu.jpeg'

const HeroSection = ()  => {

    const text = 'Muskari';
  
    const getRandomValue = (min, max) => {
      return Math.random() * (max - min) + min;
    };
  
    const getRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };
  
    const getLetterStyle = () => {
      return {
        position: 'relative',
        top: `${getRandomValue(-5, 5)}px`,
        left: `${getRandomValue(-5, 5)}px`,
        transform: `rotate(${getRandomValue(-10, 10)}deg) scale(${getRandomValue(0.9, 1.1)})`,
        fontSize: `${getRandomValue(3.4, 6.1)}em`,
        color: getRandomColor(), // Add random color here
        display: 'inline-block',
      };
    };
  

  return (
    <section className="hero container">
      <div className="hero-content">
      <div className="funny-text" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '0vh',  }}>
      <div className="random-title">
        {text.split('').map((letter, index) => (
          <span key={index} style={getLetterStyle()}>
            <h2>{letter}</h2>
          </span>
        ))}
      </div>
    </div>
        <div className="muskari-hero-text">
          <p className="muskari-small-title">Tarjoa lapselle musiikin maailma!</p>
          <p>
            Muskari eli musiikkileikkikoulu tarjoaa lapselle oman ihanan harrastuksen,
            jossa lapsi viihtyy, oppii ja saa elämyksiä.
            Musiikkikasvatuksella on tutkitusti tärkeä ja merkittävä osa lapsen
            kielellisen, motorisen, emotionaalisen ja sosiaalisen kehityksen tukemisessa.
          </p>
          <br></br>
          <p>
            Opetuksen tavoitteena on kehittää lapsen laulu- ja soittotaitoa,
            rytmi- ja melodiatajua, kuuntelukykyä sekä musiikillista ilmaisua.
          </p>
          <p>Musiikkiopiston muskariopettajat ovat musiikkikasvatuksen ammattilaisia.</p>
          <br></br>
          <p>Tutustu lukuvuoden 2025—2026 ryhmiin <a href="https://docs.google.com/spreadsheets/d/135NHVM3y8EZ1Th1vclYWP5HdBgPPpSoH/edit?usp=drive_link&ouid=109116586319455381401&rtpof=true&sd=true" target="blank">TÄSTÄ</a> ja ilmoittaudu mukaan <a href="https://kum.eepos.fi/#/forms" target="blank"> TÄSTÄ</a></p>
          
          <p>Hinnaston löydät <a href="/hinnastomuskari">TÄÄLTÄ</a></p>
          <p>Tervetuloa tutustumaan muskariin ensimmäisen kerran maksutta!</p>


        </div>
      </div>
      {/*
        <div className="muskari-infoa">

            <div className="muskari-infoa-text">
              <h3 className="muskari-small-title">Muskariryhmät</h3>
              <p>Tutustu lukuvuoden 2025—2026 ryhmiin <a href="https://docs.google.com/spreadsheets/d/135NHVM3y8EZ1Th1vclYWP5HdBgPPpSoH/edit?usp=drive_link&ouid=109116586319455381401&rtpof=true&sd=true" target="blank">TÄSTÄ</a></p>
              
              <p> Muskariin ja lauluvalmennukseen pääset ilmoittautumaan <a href="https://kum.eepos.fi/#/forms" target="blank"> TÄSTÄ</a></p>
              <p>Tutustu muskarin hinnastoon <a href="/hinnastomuskari">TÄSTÄ</a></p>

              <p>Tervetuloa tutustumaan muskariin ensimmäisen kerran maksutta!</p>

            </div>
            <div className="muskari-infoa-image right">
              <img src={MuskariViulu} alt="Right Muskari Info" />
            </div>
        </div>
        */}
        <br></br>
        <MukariContent />
          <div style={{"textAlign": "center", padding: "20px", marginBottom: "3rem"}}>
            <h3>Opetuspisteemme</h3>
            <br></br>
            <p style={{ fontSize: "1.1rem", marginBottom: "4px"}}>Järvenpään ryhmien opetuspisteet:</p>
              <p><strong>Musiikkiopisto</strong> (Seutulantie 8)</p>
              <p><strong>Kartanon koulu</strong> (Kaskitie 10)</p>
              <br></br>
              <p style={{ fontSize: "1.1rem", marginBottom: "4px"}}>Tuusulan ryhmien opetuspisteet:</p>
              <p><strong>Monio</strong>&nbsp;(Patalijoonantie 6)</p>
              {/*} <p><strong>Kirjasto </strong>(Autoasemankatu 2)</p> */}
              <p><strong>Päiväkoti Venlantupa</strong> (Anttilantie 20)</p>
          </div>

    </section>
  );
};

export default HeroSection;