import React from 'react';
import './opettajat.css';
import Opet from '../images/opettajat.jpg';
import opetJson from '../opettajat/opet.json';

const Opettajat = () => {
  return (
    <div style={{ marginTop: '120px', textAlign: 'center' }} className="container">
      <img
        style={{ width: '100%', borderRadius: '20px', marginTop: '10px', maxHeight: '800px' }}
        src={Opet}
        alt="Kum-opettajat"
      />

      <p style={{ padding: '20px'}}>Sähköpostiosoitteet ovat muotoa etunimi.sukunimi@jarvenpaa.fi</p>

      <table className="opeTable">
        <thead>
          <tr>
            <th>Nimi</th>
            <th>Opetusaine</th>
          </tr>
        </thead>
        <tbody>
          {opetJson.map((opettaja, index) => (
            <tr key={index}>
              <td style={{ fontWeight: 'bold' }}>{opettaja.name}</td>
              <td>{opettaja.data}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Opettajat;