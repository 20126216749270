/* eslint-disable react-hooks/rules-of-hooks */

import React from 'react'
import './toimipisteet.css'
import imJp from '../../images/jpaa-kuva.jpg'
import rehtori from '../../images/Sauli.jpg'
import varaRehtori from '../../images/jp.JPG';
import jarvenpaatalo from '../../images/jarvenpaaTalo.jpg'
import Taina from '../../images/Taina2.jpg';
import Helmet from 'react-helmet';
import Monio from '../../images/monio2.jpeg'


export default function OwnMap(){
    const  ToimiYhteys = () => {
        return(
          <div className="map-buttons container">          
              <br></br>
              
                <div style={{ height: "auto"}} className="map-buttons">
                <div className="map-flex" style={{ marginBottom: "7%"}}> 
                    <h4>Toimipisteet</h4>
                  
                    <div  className="toimisto">
                      <div className="card" style={{ backgroundColor: "white"}}>          
                          <img src={imJp} width='100%' alt="kum-toimpiste-järvenpää"></img>
                          <div className='rehtori-own' style={{ height: "150px", backgroundColor: "white"}}>
                            <h3>Järvenpään toimipiste / </h3>          
                            <p> Seutulantie 8</p>
                            <a target="_blank" rel="noreferrer" href='https://goo.gl/maps/JG7DoVyJS1msZXzy9'>Katso kartalta</a>
                          </div>
                      </div>
                      
                      <div className="card" style={{ backgroundColor: "white", border: "none"}}>   
                          <img src={Monio} alt="kum-toimpiste-tuusula" width="100%"></img>
                          <div className='rehtori-own' style={{ height: "150px", backgroundColor: "white"}}>
                            <h3>Tuusulan toimipiste / </h3>
                            <p>Pataljoonantie 6</p>
                            <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/XhS2kSY2cC4bcJHo7">Katso kartalta</a>
                          </div>
                         
                      </div>

                      <div className="card" style={{ backgroundColor: "white"}}>
                          <img src={jarvenpaatalo} width="100%" alt="kum-toimpiste-jarvenpaa-talo"></img>
                          <div className='rehtori-own' style={{ height: "150px", backgroundColor: "white"}}>
                            <h3>Järvenpää-talon toimipiste / </h3>
                            <p>Hallintokatu 4</p>
                            <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/zPm4t7TDdPswENyf8">Katso kartalta</a>
                          </div>
                      </div>

                    </div>
                          <h5 style={{ textAlign: "center", fontSize: "16px", fontWeight: "400"}}>* Lisäksi musiikkiopisto toimii monilla alueen kouluilla ja päiväkodeilla</h5>

                </div>

                <div className="toimisto-all">
                    
                    <div className="toimisto-allText">
                    
                        <h3>Toimisto</h3>
                        
                        <p>Oppilasasiat, lukukausimaksut</p>
                        <p>Keskisen Uudenmaan Musiikkiopisto</p>
                        <p>Seutulantie 8, 04410 Järvenpää (2. krs)</p>
                        <p>Pataljoonantie 6, 04300 Tuusula  (1.krs)</p>
                        <p>puh. 040 315 2803</p>
                        <a href="mailto:musiikkiopisto(at)jarvenpaa.fi">musiikkiopisto(at)jarvenpaa.fi</a>
                        
                        <br></br>
                        <br></br>

                        <h3>Toimiston puhelin- ja palveluajat:</h3>

                          <p>Maanantaisin klo 13.00-16.00 Järvenpäässä</p>
                          <p>Tiistaisin klo 13.00–16.00 Tuusulassa </p>
                          <p>Keskiviikkoisin klo 13.00–16.00 Järvenpäässä</p>
                          <p>Torstaisin klo 13.00–16.00 Järvenpäässä</p>
                          <br></br>
                         

                        <p><strong>Loma-aikoina ei palveluaikoja, sovi tapaaminen etukäteen. </strong></p>
                    </div>

                </div>
                <br></br>
                <br></br>
                <div className="toimisto">
                  <div class="card">                  
                      <img style={{borderRadius: "50%", marginTop: "10px"}} src={rehtori} alt="Kum Sauli Tyni" width="auto" />   
                      <div className="rehtori-own">
                          <h3>Rehtori Sauli Tyni</h3>  
                          <p>puh. 040 315 2845</p>
                          <p>sauli.tyni(at)jarvenpaa.fi </p>
                          <p>Sovi tapaamisaika etukäteen.</p>
                      </div>
                  </div>

                  <div class="card">
                      <img style={{borderRadius: "50%" , marginTop: "10px"}} src={varaRehtori} alt="Kum-Juha-Pekka-Putkonen" width="auto" />
                      <div className="rehtori-own" style={{ minHeight: "120px"}}>
                          <h3>Apulaisrehtori Juha-Pekka Putkonen</h3>
                          <p>puh. 040 315 2864</p>
                          <p>juha-pekka.putkonen(at)jarvenpaa.fi</p>
                          <p>Sovi tapaamisaika etukäteen.</p>
                      </div>
                  </div>

                  <div class="card">
                      <img style={{borderRadius: "50%", marginTop: "10px"}} src={Taina} alt="Kum-Taina-Perttilä" width="auto" />
                      <div className="rehtori-own">
                        <h3>Opintosihteeri Taina Perttilä </h3>
                          
                        <p>puh. 040 315 2803</p>
                        <p>taina.perttila(at)jarvenpaa.fi</p>
                        <p>Toimiston palveluajat.</p>
                      </div>
                  </div>
                
              </div>
              </div>
            <br></br>
         </div>
      )
      
      
    }

    return(
       <div className="toimipisteet">
        <Helmet>
          <title>Toimipisteet ja yhteystiedot</title>
          <meta name="description" content="Keskisen uudenmaan musiikkiopiston yhteystiedot toimipisteet ja opettajat. Rehtori Pirre Raijas. Apulaisrehtori Juha-Pekka Putkonen "></meta>
          <link rel="canonical" href="http://kum.fi/toimipisteet"></link>
        </Helmet>
          
        <div class="css-1ymh205" >
          <div className="yhteyis-hinnasto">
            <i className='fas fa-location-arrow' style={{fontsize:'40px', marginTop: "0px"}}><span><i class="fa fa-address-book" aria-hidden="true"></i></span></i>
          </div>
            <h2>Toimipisteet / Yhteystiedot</h2>
            <br></br>
        </div>      
          <ToimiYhteys />      
        </div>
       
    )
}

